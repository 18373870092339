import React, { Component } from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import * as echarts from 'echarts'
import { Layout } from "@elastic/react-search-ui-views";
import { Empty, message, Switch } from 'antd';
import 'echarts-wordcloud';
axios.defaults.timeout = 60000;
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
class ReactEcharts extends Component { 
    constructor(props) {
        super(props)
        this.state = {
           searchTerm : '',
           hideDom: false,
           dataList: [],
           loading: false,
           checked: true
        }
    }
    componentDidMount() {
        //初始化图表
        // this.initChart();
        if (window['searchValue']) {// 自动搜索
          this.setState({
            searchTerm: window['searchValue']
          }, () => {
            this.onSearch()
          })
        }
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        //更新图表
        if (newProps.isActive && window['searchValue']) {// 自动搜索
          this.setState({
            searchTerm: window['searchValue']
          }, () => {
            this.onSearch()
          })
        }
    }
    initWordCloud(data) {
    let myChart = echarts.getInstanceByDom(document.getElementById('keyword-wordCloud'))
      if (myChart == null) { // 如果不存在，就进行初始化
        let chartDom = document.getElementById('keyword-wordCloud');
        myChart = echarts.init(chartDom);
      }
      let option;
      let datalist = []
      for (let i = 0; i < data[1].length; i++) {
          let obj = {
              name: data[1][i].key,
              value: data[1][i].val
          }
          datalist.push(obj)
      }
      option = {
          title: {
              text: data[0]
          },
          series: [{
              type: 'wordCloud',
      
              // The shape of the "cloud" to draw. Can be any polar equation represented as a
              // callback function, or a keyword present. Available presents are circle (default),
              // cardioid (apple or heart shape curve, the most known polar equation), diamond (
              // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.
      
              shape: 'circle',
      
              // Keep aspect ratio of maskImage or 1:1 for shapes
              // This option is supported from echarts-wordcloud@2.1.0
              keepAspect: false,
      
              // A silhouette image which the white area will be excluded from drawing texts.
              // The shape option will continue to apply as the shape of the cloud to grow.
      
              // maskImage: maskImage,

              // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
              // Default to be put in the center and has 75% x 80% size.
      
              left: 'center',
              top: 'center',
              width: '70%',
              height: '80%',
              right: null,
              bottom: null,
      
              // Text size range which the value in data will be mapped to.
              // Default to have minimum 12px and maximum 60px size.
      
              sizeRange: [12, 60],
      
              // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45
      
              rotationRange: [-90, 90],
              rotationStep: 45,
      
              // size of the grid in pixels for marking the availability of the canvas
              // the larger the grid size, the bigger the gap between words.
      
              gridSize: 8,
      
              // set to true to allow word being draw partly outside of the canvas.
              // Allow word bigger than the size of the canvas to be drawn
              drawOutOfBound: false,
      
              // If perform layout animation.
              // NOTE disable it will lead to UI blocking when there is lots of words.
              layoutAnimation: true,
      
              // Global text style
              textStyle: {
                  fontFamily: 'sans-serif',
                  fontWeight: 'bold',
                  // Color can be a callback function or a color string
                  color: function () {
                      // Random color
                      return 'rgb(' + [
                          Math.round(Math.random() * 160),
                          Math.round(Math.random() * 160),
                          Math.round(Math.random() * 160)
                      ].join(',') + ')';
                  }
              },
              emphasis: {
                  focus: 'self',
      
                  textStyle: {
                      textShadowBlur: 10,
                      textShadowColor: '#333'
                  }
              },
      
              // Data is an array. Each array item must have name and value property.
              data: datalist
          }]
      }
      option && myChart.setOption(option);
      return myChart;
    }
    initBarChart(data) {
      let myChart = echarts.getInstanceByDom(document.getElementById(data[0]))
      if (myChart == null) { // 如果不存在，就进行初始化
        let chartDom = document.getElementById(data[0]);
        myChart = echarts.init(chartDom);
      }
      let option;
      if (data[0] === 'year') { // 按年份排序
          data[1] = data[1].sort((a,b) => {
              return Number(b.key) - Number(a.key);
          })
      }
      let axisLabelRotate = 0;
      if (data[0] === 'keyword') {
        axisLabelRotate = -30;
      }
      option = {
          title: {
              text: data[0]
            },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                show: true
              }
            }
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          calculable: true,
          grid: {
            top: '12%',
            left: '1%',
            right: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data[1].map(_ => _.key.toString()),
              minInterval: 1,
              axisLabel: {
                rotate: axisLabelRotate
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              minInterval: 1
            }
          ],
          dataZoom: [
            {
              show: data[1].length > 1,
              start: 0,
              end: 10
            }
          ],
          series: [
            {
              type: 'bar',
              data: data[1].map(_ => _.val)
            }
          ]
        };
        option && myChart.setOption(option);
        return myChart;
    }
    /*生成图表，做了判断，如果不去判断dom有没有生成，
      每次更新图表都要生成一个dom节点*/
    initChart(props) {
        this.state.dataList.forEach((data) => {
            if (data[0] === 'meshterm' || data[0] === 'author') {
              let myChart = echarts.getInstanceByDom(document.getElementById(data[0]))
              if (myChart == null) { // 如果不存在，就进行初始化
                let chartDom = document.getElementById(data[0]);
                myChart = echarts.init(chartDom);
              }
              let option;
              data[1].reverse();
              option = {
                  title: {
                      text: data[0]
                    },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow',
                      label: {
                        show: true
                      }
                    }
                  },
                  toolbox: {
                    show: true,
                    feature: {
                      mark: { show: true },
                      dataView: { show: true, readOnly: false },
                      magicType: { show: true, type: ['bar'] },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }
                  },
                  calculable: true,
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  yAxis: [
                    {
                      type: 'category',
                      data: data[1].map(_ => _.key),
                      minInterval: 1
                    }
                  ],
                  xAxis: [
                    {
                      type: 'value',
                      minInterval: 1
                    }
                  ],
                  dataZoom: [
                    {
                      show: data[1].length > 1,
                      yAxisIndex: 0,
                      filterMode: 'empty',
                      width: 30,
                      start: 95,
                      end: 100,
                      height: '80%',
                      showDataShadow: false,
                      left: '95%'
                    }
                  ],
                  series: [
                    {
                      type: 'bar',
                      data: data[1].map(_ => _.val)
                    }
                  ]
                };
                option && myChart.setOption(option);
                return;
            }
            this.initBarChart(data);

        }) 
    }
    onSearch(searchTerm) {
        if (!this.state.searchTerm) {
            message.warning('Paper Analytics 搜索值不能为空');
            return;
        }
        const params = {
            query: this.state.searchTerm
        }
        window['searchValue'] = this.state.searchTerm
        axios.get('http://120.24.46.127:8088/pm-paper/getGroupByPMIDMap', {params: params}).then((res) => {
            if (!res.data) {
              message.warning('查询结果为空');
              return;
            }
            this.setState({
                dataList: Object.entries(res.data).reverse(),
                hideDom: Object.entries(res.data).length > 0
            })
            var keyword = this.state.dataList.find(data => data[0] === 'keyword')
            if (keyword && this.state.checked) {
              this.initWordCloud(keyword)
            }
            this.initChart();
        }).catch( (error) => {
          message.warning(error.message);
        })
    }
    onClick(checked, e) {
      this.setState({
        checked: checked
      })
    }
    inputValue(e) {
      this.setState({
          searchTerm: e.target.value
      })
    }
    render() {
        return (
            <div className='paper-analytics-container' >
            {
                !this.state.hideDom ? <Empty /> : null
            }    
            <Layout               
               header={
                    <div className='search-group'>
                        <input type='text' className='search-input' placeholder='Search' value={this.state.searchTerm} onChange={this.inputValue.bind(this)} />
                        <button className='search-btn' onClick={ this.onSearch.bind(this) }>Search</button>
                    </div>
                }
                bodyContent={
                  <div className='echart-bodyContent'>
                    {
                      this.state.hideDom && this.state.dataList.find(_ => _[0] === 'keyword') ? <Switch checkedChildren="WordCloud" className='echart-switch' unCheckedChildren="Bar" onClick={this.onClick.bind(this)} checked={this.state.checked} /> : null
                    }
                    <div id="keyword-wordCloud" className={this.state.checked && this.state.dataList.find(_ => _[0] === 'keyword') ? 'echart-box' : 'echart-box hiddenDom'}></div>
                    { this.state.dataList.map((item, i) => {
                        return <div key={i} id={item[0]} className={(this.state.checked && item[0] === 'keyword') ? 'echart-box hiddenDom' : 'echart-box'}></div>
                     })
                    }
                  </div>
                  }
           />
           </div>
        );
    }
};
ReactEcharts.propTypes = {
  isActive: PropTypes.bool.isRequired
};
export {ReactEcharts as default};