import React from "react";
import { Tabs, Tag } from 'antd';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import './static/iconfont/iconfont.css'
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import PaperAnalytics from "./pages/paperAnalytics";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";
import {
  reviewBuildAutocompleteQueryConfig,
  reviewBuildFacetConfigFromConfig,
  reviewBuildSearchOptionsFromConfig,
  reviewBuildSortOptionsFromConfig,
  getReviewConfig,
  getReviewFacetFields
} from "./config/review-config-helper";
import {
  paperBuildAutocompleteQueryConfig,
  paperBuildFacetConfigFromConfig,
  paperBuildSearchOptionsFromConfig,
  paperBuildSortOptionsFromConfig,
  getPaperConfig,
  getPaperFacetFields
} from "./config/paper-config-helper";
const { TabPane } = Tabs;
const { hostIdentifier, searchKey, endpointBase, engineName, isFilterable } = getConfig();
const reviewConnector = new AppSearchAPIConnector({
  searchKey:getReviewConfig()['searchKey'],
  engineName:getReviewConfig()['engineName'],
  hostIdentifier:getReviewConfig()['hostIdentifier'],
  endpointBase:getReviewConfig()['endpointBase'],
  multiFields: ['expect_brand', 'ingredient']
});
const paperConnector = new AppSearchAPIConnector({
  searchKey:getPaperConfig()['searchKey'],
  engineName:getPaperConfig()['engineName'],
  hostIdentifier:getPaperConfig()['hostIdentifier'],
  endpointBase:getPaperConfig()['endpointBase'],
  multiFields: ['keyword', 'meshterm', 'author']
});
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
  multiFields: ['author']
});
const reviewConfig = {
  searchQuery: {
    facets: reviewBuildFacetConfigFromConfig(),
    ...reviewBuildSearchOptionsFromConfig()
  },
  autocompleteQuery: reviewBuildAutocompleteQueryConfig(),
  apiConnector: reviewConnector,
  alwaysSearchOnInitialLoad: true
};
const paperConfig = {
  searchQuery: {
    facets: paperBuildFacetConfigFromConfig(),
    ...paperBuildSearchOptionsFromConfig()
  },
  autocompleteQuery: paperBuildAutocompleteQueryConfig(),
  apiConnector: paperConnector,
  alwaysSearchOnInitialLoad: true
};
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true
};
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: true,
      activeKey: '1',
      authorList: []
    }
  }
  onChange(activeKey) {
    this.setState({
      isActive: activeKey === '4',
      activeKey: activeKey
    })
  }
  setAuthorList(author) {
    if (this.state.authorList.includes(author)) return;
    let authorList = this.state.authorList
    if (authorList.length >= 1) {
      authorList[0] = author;
    } else {
      authorList.push(author)
    }
    this.setState({
      authorList: authorList
    }, () => {
      window['authorList'] = authorList
    })
    var searchBtn = window.document.querySelector(`.searchBox-${this.state.activeKey} .sui-search-box__submit`)
    searchBtn && searchBtn.click(); // 主动点一下搜索按钮
  }
  onClickCloseTag(author) {
    if (this.state.authorList.includes(author)) {
      let authorList = this.state.authorList
      let index = authorList.indexOf(author);
      authorList.splice(index, 1);
      this.setState({
        authorList: authorList
      })
    }
  }
  render() {
    return (
      <Tabs defaultActiveKey="1" type="card" onChange={this.onChange.bind(this)}>
      <TabPane tab="Review" key="1">
      <SearchProvider config={reviewConfig}>
        <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
          {({ wasSearched }) => {
            return (
              <div className="App">
                  <Layout
                    header={
                    <div>
                      <SearchBox className="searchBox-1" autocompleteSuggestions={true} />
                    </div>
                    }
                    sideContent={
                      <div>
                        {wasSearched && (
                          <Sorting
                            label={"Sort by"}
                            sortOptions={reviewBuildSortOptionsFromConfig()}
                          />
                        )}
                        {getReviewFacetFields().map(field => (
                          <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
                        ))}
                      </div>
                    }
                    bodyContent={
                      <ErrorBoundary>
                        <Results
                          titleField={getReviewConfig().titleField}
                          urlField={getReviewConfig().urlField}
                          thumbnailField={getReviewConfig().thumbnailField}
                          shouldTrackClickThrough={true}
                          setAuthorList = { this.setAuthorList.bind(this) }
                        />
                      </ErrorBoundary>
                    }
                    bodyHeader={
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </React.Fragment>
                    }
                    bodyFooter={<Paging />}
                  />
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
      </TabPane>
      <TabPane tab="Figure" key="2"> 
      <SearchProvider config={config}>
        <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
          {({ wasSearched }) => {
            return (
              <div className="App fig-container">
                  <Layout
                    header={
                    <div>
                      <SearchBox className="searchBox-2" autocompleteSuggestions={true} />
                      {
                        this.state.authorList.map(author => {
                          return <Tag key={author} closable onClose={() => this.onClickCloseTag(author)}>
                            {author}
                          </Tag>
                        })
                      }
                    </div>
                    }
                    sideContent={
                      <div>
                        {wasSearched && (
                          <Sorting
                            label={"Sort by"}
                            sortOptions={buildSortOptionsFromConfig()}
                          />
                        )}
                        {getFacetFields().map(field => (
                          <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
                        ))}
                      </div>
                    }
                    bodyContent={
                      <ErrorBoundary>
                      <Results
                        titleField={getConfig().titleField}
                        titleFieldCn = {getConfig().titleFieldCn}
                        urlField={getConfig().urlField}
                        thumbnailField={getConfig().thumbnailField}
                        shouldTrackClickThrough={true}
                        setAuthorList = { this.setAuthorList.bind(this) }
                      />
                      </ErrorBoundary>
                    }
                    bodyHeader={
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </React.Fragment>
                    }
                    bodyFooter={<Paging />}
                  />
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
      </TabPane>
      <TabPane tab="Paper" key="3"> 
      <SearchProvider config={paperConfig}>
        <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
          {({ wasSearched }) => {
            return (
              <div className="App paper-container">
                  <Layout
                    header={
                    <div>
                      <SearchBox className="searchBox-3" autocompleteSuggestions={true}/>
                      {
                        this.state.authorList.map(author => {
                          return <Tag key={author} closable onClose={() => this.onClickCloseTag(author)}>
                            {author}
                          </Tag>
                        })
                      }
                    </div>
                    }
                    sideContent={
                      <div>
                        {wasSearched && (
                          <Sorting
                            label={"Sort by"}
                            sortOptions={paperBuildSortOptionsFromConfig()}
                          />
                        )}
                        {getPaperFacetFields().map(field => (
                          <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
                        ))}
                      </div>
                    }
                    bodyContent={
                      <ErrorBoundary>
                      <Results
                        titleField={getPaperConfig().titleField}
                        urlField={getPaperConfig().urlField}
                        titleFieldCn = {getPaperConfig().titleFieldCn}
                        isImage = {false}
                        customUrl = {true}
                        thumbnailField={getPaperConfig().thumbnailField}
                        shouldTrackClickThrough={true}
                        setAuthorList = { this.setAuthorList.bind(this) }
                      />
                      </ErrorBoundary>
                    }
                    bodyHeader={
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </React.Fragment>
                    }
                    bodyFooter={<Paging />}
                  />
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
      </TabPane>
      <TabPane tab="Paper Analytics" key="4"> 
        <PaperAnalytics isActive = {this.state.isActive} />
      </TabPane>
     </Tabs>
    );
}
}
export {App as default};
// export default function App() {
//   return (
//     <Tabs defaultActiveKey="1" type="card" onChange={onChange}>
//     <TabPane tab="Review" key="1">
//     <SearchProvider config={reviewConfig}>
//       <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
//         {({ wasSearched }) => {
//           return (
//             <div className="App">
//                 <Layout
//                   header={
//                   <div>
//                     <SearchBox autocompleteSuggestions={true} />
//                   </div>
//                   }
//                   sideContent={
//                     <div>
//                       {wasSearched && (
//                         <Sorting
//                           label={"Sort by"}
//                           sortOptions={reviewBuildSortOptionsFromConfig()}
//                         />
//                       )}
//                       {getReviewFacetFields().map(field => (
//                         <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
//                       ))}
//                     </div>
//                   }
//                   bodyContent={
//                     <ErrorBoundary>
//                       <Results
//                         titleField={getReviewConfig().titleField}
//                         urlField={getReviewConfig().urlField}
//                         thumbnailField={getReviewConfig().thumbnailField}
//                         shouldTrackClickThrough={true}
//                       />
//                     </ErrorBoundary>
//                   }
//                   bodyHeader={
//                     <React.Fragment>
//                       {wasSearched && <PagingInfo />}
//                       {wasSearched && <ResultsPerPage />}
//                     </React.Fragment>
//                   }
//                   bodyFooter={<Paging />}
//                 />
//             </div>
//           );
//         }}
//       </WithSearch>
//     </SearchProvider>
//     </TabPane>
//     <TabPane tab="Fig" key="2"> 
//     <SearchProvider config={config}>
//       <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
//         {({ wasSearched }) => {
//           return (
//             <div className="App fig-container">
//                 <Layout
//                   header={
//                   <div>
//                     <SearchBox autocompleteSuggestions={true} />
//                   </div>
//                   }
//                   sideContent={
//                     <div>
//                       {wasSearched && (
//                         <Sorting
//                           label={"Sort by"}
//                           sortOptions={buildSortOptionsFromConfig()}
//                         />
//                       )}
//                       {getFacetFields().map(field => (
//                         <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
//                       ))}
//                     </div>
//                   }
//                   bodyContent={
//                     <ErrorBoundary>
//                     <Results
//                       titleField={getConfig().titleField}
//                       titleFieldCn = {getConfig().titleFieldCn}
//                       urlField={getConfig().urlField}
//                       thumbnailField={getConfig().thumbnailField}
//                       shouldTrackClickThrough={true}
//                     />
//                     </ErrorBoundary>
//                   }
//                   bodyHeader={
//                     <React.Fragment>
//                       {wasSearched && <PagingInfo />}
//                       {wasSearched && <ResultsPerPage />}
//                     </React.Fragment>
//                   }
//                   bodyFooter={<Paging />}
//                 />
//             </div>
//           );
//         }}
//       </WithSearch>
//     </SearchProvider>
//     </TabPane>
//     <TabPane tab="Paper" key="3"> 
//     <SearchProvider config={paperConfig}>
//       <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
//         {({ wasSearched }) => {
//           return (
//             <div className="App paper-container">
//                 <Layout
//                   header={
//                   <div>
//                     <SearchBox autocompleteSuggestions={true}/>
//                   </div>
//                   }
//                   sideContent={
//                     <div>
//                       {wasSearched && (
//                         <Sorting
//                           label={"Sort by"}
//                           sortOptions={paperBuildSortOptionsFromConfig()}
//                         />
//                       )}
//                       {getPaperFacetFields().map(field => (
//                         <Facet key={field} field={field} label={field} isFilterable={isFilterable} />
//                       ))}
//                     </div>
//                   }
//                   bodyContent={
//                     <ErrorBoundary>
//                     <Results
//                       titleField={getPaperConfig().titleField}
//                       urlField={getPaperConfig().urlField}
//                       titleFieldCn = {getPaperConfig().titleFieldCn}
//                       isImage = {false}
//                       customUrl = {true}
//                       thumbnailField={getPaperConfig().thumbnailField}
//                       shouldTrackClickThrough={true}
//                     />
//                     </ErrorBoundary>
//                   }
//                   bodyHeader={
//                     <React.Fragment>
//                       {wasSearched && <PagingInfo />}
//                       {wasSearched && <ResultsPerPage />}
//                     </React.Fragment>
//                   }
//                   bodyFooter={<Paging />}
//                 />
//             </div>
//           );
//         }}
//       </WithSearch>
//     </SearchProvider>
//     </TabPane>
//     <TabPane tab="Paper Analytics" key="4"> 
//       <PaperAnalytics isActive = {isActive} />
//     </TabPane>
//    </Tabs>
//   );
// }
